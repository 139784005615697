import { chakra, Grid, useMultiStyleConfig, VStack } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import {
	InlineLinkButtonFragment,
	InlineModalButtonFragment,
} from "src/__generated__/client.codegen";
import { CMSButton } from "src/components/CMSButton/CMSButton";
import { DatoAsset, DatoAssetData } from "src/components/Dato/DatoAsset";
import { HeadingLevelBoundary, Hx } from "src/components/Heading/Heading";
import {
	StructuredText,
	StructuredTextData,
} from "src/components/StructuredText/StructuredText";
import { readFromEnumFactory } from "src/lib/utils";
import type { HlsPlayerProps } from "src/components/HlsPlayer";

const HlsPlayer = dynamic<HlsPlayerProps>(async () =>
	import("src/components/HlsPlayer").then((mod) => mod.HlsPlayer),
);

export const variants = ["left", "top"] as const;

type Variant = (typeof variants)[number];

export const readVariant = readFromEnumFactory(variants, "left");

type Image = DatoAssetData & { type: "image" };
type Video = { src: string; poster: string; type: "video" };

type Asset = Image | Video;

export type VideoSectionProps = {
	title?: string;
	topline?: string;
	button?: InlineLinkButtonFragment | InlineModalButtonFragment;
	asset: Asset;
	topic?: string;
	topicSubline?: StructuredTextData;
	text?: StructuredTextData;
	persona?: {
		icon: DatoAssetData;
		title: string;
		text?: StructuredTextData;
	};
	variant: Variant;
	fullBleed?: boolean;
};

const breakpoint = "md";

export const VideoSection: React.FC<VideoSectionProps> = ({
	topline,
	title,
	button,
	asset,
	topic,
	topicSubline,
	text,
	persona,
	variant,
	fullBleed,
}) => {
	const styles = useMultiStyleConfig("VideoSection", {
		variant,
		fullBleed,
		breakpoint,
		hasTopic: Boolean(topic),
	});

	return (
		<Grid sx={styles.wrapper}>
			{(topline || title || button) && (
				<VStack sx={styles.intro}>
					{topline && <chakra.p fontSize="xl">{topline}</chakra.p>}
					{title && <Hx sx={styles.title}>{title}</Hx>}
					{button && (
						<chakra.div pt="1.75rem">
							<CMSButton {...button} />
						</chakra.div>
					)}
				</VStack>
			)}

			<chakra.div __css={styles.imageWrapper}>
				{asset.type === "image" && (
					<DatoAsset
						data={asset}
						style={{ maxWidth: "100%", height: "100%" }}
						datoImageProps={{
							objectFit: "cover",
						}}
					/>
				)}
				{asset.type === "video" && (
					<HlsPlayer
						sx={styles.video}
						src={asset.src}
						poster={asset.poster}
						autoPlay={true}
						loop={true}
						playsInline={true}
						muted={true}
						width="full"
						height="full"
						objectFit="cover"
					/>
				)}
			</chakra.div>

			<HeadingLevelBoundary>
				<chakra.div __css={styles.innerWrapper}>
					{topic && (
						<VStack sx={styles.topicWrapper}>
							<Hx size="h2">{topic}</Hx>
							{topicSubline && (
								<StructuredText data={topicSubline} />
							)}
						</VStack>
					)}

					{text && (
						<chakra.div __css={styles.text}>
							<StructuredText data={text} />
						</chakra.div>
					)}
				</chakra.div>

				{persona && (
					<chakra.div __css={styles.personaWrapper}>
						<DatoAsset
							__css={styles.personaIcon}
							data={persona.icon}
							as="div"
						/>
						{persona.text && <StructuredText data={persona.text} />}
					</chakra.div>
				)}
			</HeadingLevelBoundary>
		</Grid>
	);
};

// 🔬 e2e: story
